import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-nirf',
  templateUrl: './nirf.component.html',
  styleUrls: ['./nirf.component.scss']
})
export class NirfComponent implements OnInit {
  // pdfSrc='/assets/pdf/Guru_Nanak_Khalsa_College_Yamuna_Nagar20210219.pdf';
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1GfLWt9JXxu8hCKj-4gVIMwAlnYW2Kiu4/preview', 
    'https://drive.google.com/file/d/1E6LK3shTZ9DNIcs8ztx1g7cihgks3e_t/preview' ,
    'https://drive.google.com/file/d/1lvaXBxYQJk04yOauPETBUMbRJ35Tkyx9/preview', 
    'https://drive.google.com/file/d/1Mu8KZAK8MGfUneWQGtecAuI5109NQEDo/preview',
    'https://drive.google.com/file/d/1s-nVVrN2t64HQAPXDXtzG3-XFZW-SIsg/preview',
    'https://drive.google.com/file/d/1PHocohG2OFlY6Bx-w5A-j_sExPItjTIK/preview'
    ];
  src: string;

  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('2018');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '2018') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2019') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '2020') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '2021') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      this.src = this.pdfSrc[3];
    }
    if (year == '2022') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      this.src = this.pdfSrc[4];
    }
    if (year == '2023') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      this.src = this.pdfSrc[5];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
