<!-- <div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    Header
                    <header class="header">
                        <h5>LESSON PLAN</h5>
                    </header>
                    Header
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">DEPARTMENT OF BIOTECHNOLOGY</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">DEPARTMENT OF BOTANY</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">DEPARTMENT OF CHEMISTRY</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">DEPARTMENT OF COMMERCE-B.COM</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">DEPARTMENT OF COMMERCE-M.COM</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">DEPARTMENT OF COMPUTER SCIENCE AND APPLICATIONS-BCA</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">DEPARTMENT OF COMPUTER SCIENCE AND APPLICATIONS-BSC CA ,
                                CS</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">DEPARTMENT OF ECONOMICS</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">DEPARTMENT OF EDUCATION</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">DEPARTMENT OF ENGLISH</a>
                        </li>
                        <li class="eleven">
                            <a (click)="changeRoute('11')">DEPARTMENT OF GEOGRAPHY</a>
                        </li>
                        <li class="twelve">
                            <a (click)="changeRoute('12')">DEPARTMENT OF HEALTH, PHYSICA-BA </a>
                        </li>
                        <li class="thirteen">
                            <a (click)="changeRoute('13')">DEPARTMENT OF HEALTH, PHYSICAL EDUCATION AND
                                SPORTS-BPED</a>
                        </li>
                        <li class="fourteen">
                            <a (click)="changeRoute('14')">DEPARTMENT OF HEALTH, PHYSICAL EDUCATION AND SPORTS-BSC
                                SPORTS</a>
                        </li>
                        <li class="fifteen">
                            <a (click)="changeRoute('15')">DEPARTMENT OF HEALTH, PHYSICAL-PG DIPLOMA IN YOGA</a>
                        </li>
                        <li class="sixteen">
                            <a (click)="changeRoute('16')">DEPARTMENT OF HINDI</a>
                        </li>
                        <li class="seventeen">
                            <a (click)="changeRoute('17')">DEPARTMENT OF HISTORY</a>
                        </li>
                        <li class="eighteen">
                            <a (click)="changeRoute('18')">DEPARTMENT OF IC</a>
                        </li>
                        <li class="nineteen">
                            <a (click)="changeRoute('19')">DEPARTMENT OF IMB</a>
                        </li>
                        <li class="twenty">
                            <a (click)="changeRoute('20')">DEPARTMENT OF MATHEMATICS</a>
                        </li>
                        <li class="twentyone">
                            <a (click)="changeRoute('21')">DEPARTMENT OF MUSIC</a>
                        </li>
                        <li class="twentytwo">
                            <a (click)="changeRoute('22')">DEPARTMENT OF PHYSICS</a>
                        </li>
                        <li class="twentythree">
                            <a (click)="changeRoute('23')">DEPARTMENT OF POLITICAL SCIENCE</a>
                        </li>
                        <li class="twentyfour">
                            <a (click)="changeRoute('24')">DEPARTMENT OF PUBLIC ADMINISTRATION</a>
                        </li>
                        <li class="twentyfive">
                            <a (click)="changeRoute('25')">DEPARTMENT OF PUNJABI</a>
                        </li>
                        <li class="twentysix">
                            <a (click)="changeRoute('26')">DEPARTMENT OF SANSKRIT</a>
                        </li>
                        <li class="twentyseven">
                            <a (click)="changeRoute('27')">DEPARTMENT OF SOCIAL WORK</a>
                        </li>
                        <li class="twentyeight">
                            <a (click)="changeRoute('28')">DEPARTMENT OF ZOOLOGY</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    
                                    <h5 class="card-title text-center">LESSON PLAN</h5>
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div> -->


    <div id="lesson">
        <div class="container-fluid">
            <div class="row">
                
                <div class="col-md-3" *ngIf="showSideMenu">
                    <nav id="secondary-navigation">
                        <!-- Header -->
                        <header class="header">
                            <h5>Lesson Plan</h5>
                        </header>
                        <!-- /Header -->
                        <ul>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2024-2025-even" (click)="checkRoute()">Lesson Plan(2024-2025)(Even Sem)</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2024-2025-odd" (click)="checkRoute()">Lesson Plan(2024-2025)(Odd Sem)</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2023-2024-even" (click)="checkRoute()">Lesson Plan(2023-2024)(Even Sem)</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2023-2024" (click)="checkRoute()">Lesson Plan(2023-2024)(Odd Sem)</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2022-2023" (click)="checkRoute()">Lesson Plan(2022-2023)</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan/2021-2022" (click)="checkRoute()">Lesson Plan(2021-2022)</a>
                            </li>
                           
                        </ul>
                    </nav>
                </div>
                <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>