import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesComponent } from 'src/app/view/courses/courses.component';
import { GraduateComponent } from 'src/app/view/courses/graduate/graduate.component';
import { PostGraduateComponent } from 'src/app/view/courses/post-graduate/post-graduate.component';
import { RouterModule } from '@angular/router';
import { CoursesRoutingModule } from './courses-routing.module';
import { AddOnComponent } from 'src/app/view/courses/add-on/add-on.component';
import { CommerceCourseComponent } from 'src/app/view/courses/commerce-course/commerce-course.component';
import { EducationComponent } from 'src/app/view/courses/education/education.component';
import { PhysicalCourseComponent } from 'src/app/view/courses/physical-course/physical-course.component';
import { UgcComponent } from 'src/app/view/courses/ugc/ugc.component';
import { PoCoComponent } from 'src/app/view/courses/po-co/po-co.component';
import { ElectiveCoursesComponent } from 'src/app/view/courses/elective-courses/elective-courses.component';
import { LessonPlanComponent } from 'src/app/view/courses/lesson-plan/lesson-plan.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { LpTwooneTwotwoComponent } from 'src/app/view/courses/lesson-plan/lp-twoone-twotwo/lp-twoone-twotwo.component';
import { LpTwotwoTwothreeComponent } from 'src/app/view/courses/lesson-plan/lp-twotwo-twothree/lp-twotwo-twothree.component';
import { LpTwothreeTwofourComponent } from 'src/app/view/courses/lesson-plan/lp-twothree-twofour/lp-twothree-twofour.component';
import { SoftSkillComponent } from 'src/app/view/courses/soft-skill/soft-skill.component';
import { SecComponent } from "src/app/view/courses/sec/sec.component"
import { VacComponent } from "src/app/view/courses/vac/vac.component"
import { AecComponent } from "src/app/view/courses/aec/aec.component"
import { MdcComponent } from "src/app/view/courses/mdc/mdc.component"
import { LpTwothreeTwofourEvenComponent } from 'src/app/view/courses/lesson-plan/lp-twothree-twofour-even/lp-twothree-twofour-even.component';
import { LpTwofourTwofiveEvenComponent } from 'src/app/view/courses/lesson-plan/lp-twofour-twofive-even/lp-twofour-twofive-even.component';
import { LpTwofourTwofiveOddComponent } from 'src/app/view/courses/lesson-plan/lp-twofour-twofive-odd/lp-twofour-twofive-odd.component';




@NgModule({
  declarations: [
    CoursesComponent,
    GraduateComponent,
    PostGraduateComponent,
    CommerceCourseComponent,
    PhysicalCourseComponent,
    UgcComponent,
    EducationComponent,
    AddOnComponent,
    PoCoComponent,
    ElectiveCoursesComponent,
    LessonPlanComponent,
    LpTwooneTwotwoComponent,
    LpTwotwoTwothreeComponent,
    LpTwothreeTwofourComponent,
    LpTwothreeTwofourEvenComponent,
    SoftSkillComponent,
    SecComponent,
    VacComponent,
    AecComponent,
    MdcComponent,
    LpTwofourTwofiveEvenComponent,
    LpTwofourTwofiveOddComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoursesRoutingModule,
    PdfViewerModule,
    LoaderModule,
    SharedModule
  ]
})
export class CourseModule { }
