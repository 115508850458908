import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lp-twofour-twofive-odd',
  templateUrl: './lp-twofour-twofive-odd.component.html',
  styleUrls: ['./lp-twofour-twofive-odd.component.scss'],
})
export class LpTwofourTwofiveOddComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/1fKxPxXurx2tZiLwKEWHEh5ofROd85_HZ/preview',
    'https://drive.google.com/file/d/1T0jQsD-GCLxs4mvu-376VhqrlWfwGBii/preview',
    'https://drive.google.com/file/d/1R9lRBtKK_W385_lwD_sq51g8wJ6dJEYJ/preview',
    'https://drive.google.com/file/d/1H_qwuJvCJXWLAER5V95SlXgWvsfaxPGL/preview',
    'https://drive.google.com/file/d/12lVCHp9LMwfJ2xhoxccY8pbboXlE5vcp/preview',
    'https://drive.google.com/file/d/1vR-sk3IR8gHGpDUDUYOMel3pavAf3OZS/preview',
    'https://drive.google.com/file/d/121O26X7f2mVCrz_o3xbyRjdpy9zC2F5L/preview',
    'https://drive.google.com/file/d/19vof-XBTgT2yPackcgvxVOLbKlmkYTeG/preview',
    'https://drive.google.com/file/d/10FVH8sWlKxQ4TwOtYMFaVsTaJsYT1h41/preview',
    'https://drive.google.com/file/d/1y9CFKN_tmA9mYHu8LdpKQNecxqVV2yKU/preview',
    'https://drive.google.com/file/d/1cJ2fXvbMVaTSHVXLiFJnJAmpvH4w-Ulm/preview',
    'https://drive.google.com/file/d/1LwRqfuSI9en5K81E4_INpAB-PUIOn3_E/preview',
    'https://drive.google.com/file/d/1p9pYUXzYtkrdMIozs2DyZlXsLGiVZXVq/preview',
    'https://drive.google.com/file/d/1pTyeWYCbCVAWI1krQSgiMQ7uc_l9fiIl/preview',
    'https://drive.google.com/file/d/1c5qOaYgssilMC3NaRTyjDSA5aTsd6Ydg/preview',
    'https://drive.google.com/file/d/1uThRVWEXxSbP6YV7zxAk_8Oc3FzZq0Mi/preview',
    'https://drive.google.com/file/d/1dxZOQ--Grqcb4FdckiSNwFZHokgQpw70/preview',
    'https://drive.google.com/file/d/1cUw8Z3rBw1-8g-Xkl_9ih4hintOhcJ_2/preview',
    'https://drive.google.com/file/d/1Tla9X3gF5cdPK9Oiu1yfxVI0eVzgvIH-/preview',
    'https://drive.google.com/file/d/12PePZ_jb-DT8SSJnupgJ8Km8RzqTsV6C/preview',
    'https://drive.google.com/file/d/1BVX643XKfbDJaDNeaRiA66TcPJzTpM36/preview',
    'https://drive.google.com/file/d/1_WxHKFPgiD8WBnXRCXbDy-XD0bxGVAmJ/preview',
    'https://drive.google.com/file/d/1Yf4XlrqCZO9_I7GsYIiNItVwodeJNfRJ/preview',
    'https://drive.google.com/file/d/1mgfYj5GJwXNEfGtOIcsD1S5nFDMdxH7w/preview',
    'https://drive.google.com/file/d/1O5PN9znJhhZfCkZ9748e-ANawKVXfxF-/preview',
    'https://drive.google.com/file/d/1h5HggjSjylysT0Wv0GBfvbVQ3uB4cw23/preview',
    'https://drive.google.com/file/d/1jHtMSOHG9rpLGEi7V_BvHZEE2hVg7QUy/preview',
  ];

  departments = [
    { id: '1', name: 'DEPARTMENT OF ECONOMICS' },
    { id: '2', name: 'DEPARTMENT OF HISTORY' },
    { id: '3', name: 'DEPARTMENT OF YOGA' },
    { id: '4', name: 'DEPARTMENT OF ENGLISH' },
    { id: '5', name: 'DEPARTMENT OF PHYSICS' },
    { id: '6', name: 'DEPARTMENT OF MUSIC' },
    { id: '7', name: 'DEPARTMENT OF MATHS' },
    { id: '8', name: 'DEPARTMENT OF BOTANY' },
    { id: '9', name: 'DEPARTMENT OF POLITICAL SCIENCE' },
    { id: '10', name: 'DEPARTMENT OF PUBLIC ADMINISTRATION' },
    { id: '11', name: 'DEPARTMENT OF BCA' },
    { id: '12', name: 'DEPARTMENT OF SANSKRIT' },
    { id: '13', name: 'DEPARTMENT OF SOCIAL WORK' },
    { id: '14', name: 'DEPARTMENT OF HINDI' },
    { id: '15', name: 'DEPARTMENT OF B.P.Ed.' },
    { id: '16', name: 'DEPARTMENT OF GEOGRAPHY' },
    { id: '17', name: 'DEPARTMENT OF B.Ed.' },
    { id: '18', name: 'DEPARTMENT OF PUNJABI' },
    { id: '19', name: 'DEPARTMENT OF SPORTS' },
    { id: '20', name: 'DEPARTMENT OF CHEMISTRY' },
    { id: '21', name: 'DEPARTMENT OF INDUSTRIAL CHEMISTRY' },
    { id: '22', name: 'DEPARTMENT OF BIOTECHNOLOGY' },
    { id: '23', name: 'DEPARTMENT OF COMMERCE' },
    { id: '24', name: 'DEPARTMENT OF IMB' },
    { id: '25', name: 'DEPARTMENT OF ZOOLOGY' },
    { id: '26', name: 'DEPARTMENT OF B.Sc. Computer Science' },
    { id: '27', name: 'DEPARTMENT OF B.Sc. Computer Application' },
  ];

  src: string;
  isLoading = true;
  activeDepartment: string = '1';
  constructor() {}

  ngOnInit(): void {
    this.changeRoute('1');
  }
  changeRoute(year: string) {
    this.isLoading = true;
    this.activeDepartment = year;
    this.src = this.pdfSrc[parseInt(year) - 1] || ''; // Set the corresponding PDF source
  }
}
