import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticeService } from 'src/app/services/notice/notice.service';
import { ShowNoticeService } from 'src/app/services/showNotice/show-notice.service';
declare var $: any;
@Component({
  selector: 'app-notice-item',
  templateUrl: './notice-item.component.html',
  styleUrls: ['./notice-item.component.scss'],
})
export class NoticeItemComponent implements OnInit {
  id: string;
  sub: Subscription;
  urlPath = '';
  showResultTable: boolean = false;
  result: { title: string; url: string }[] = [
    {
      title: 'B.Sc. Life Sci.- 1 Sem.',
      url: 'https://drive.google.com/file/d/10PfZdaXbmgM3HdYxH7MrvM_CuPUJAqDh/view?usp=drivesdk',
    },
    {
      title: 'B.A.-3rd Sem.',
      url: 'https://drive.google.com/file/d/10ufLFj1-Em0_m1bNkTnJofhVVqJ-UJwG/view?usp=drivesdk',
    },
    {
      title: 'M.Com-1 Sem.',
      url: 'https://drive.google.com/file/d/13O1qLRfcbGPcP9xu271z3CoxOM3v9W7v/view?usp=drivesdk',
    },
    {
      title: 'B.ComGen._Hons.-3rd Sem.',
      url: 'https://drive.google.com/file/d/19u4Vp_3opVgZiN74YZLaKCV1vSetoCat/view?usp=drivesdk',
    },
    {
      title: 'M.A. Punjabi-1 Sem.',
      url: 'https://drive.google.com/file/d/1DBFiqQjjGId678PGxEM021Zo_1gUtB8q/view?usp=drivesdk',
    },
    {
      title: 'B.A.-1 Sem.',
      url: 'https://drive.google.com/file/d/1G6E8SBGMf8OalJcyHZWsyd9h-2W39Bna/view?usp=drivesdk',
    },
    {
      title: 'B.Sc. Life Sci.-3rd Sem.',
      url: 'https://drive.google.com/file/d/1NCjc_DJdYoFPYLVw4o9Rhj0QSfd4eGC6/view?usp=drivesdk',
    },
    {
      title: 'BCA-1 Sem.',
      url: 'https://drive.google.com/file/d/1NX7k0ZvAQC3oQfiDyaBqikEWUYpfnXp4/view?usp=drivesdk',
    },
    {
      title: 'BBA-1st Sem.',
      url: 'https://drive.google.com/file/d/1TgF_B1Fzk5-NFMgvbooLcd5zldiJLaa_/view?usp=drivesdk',
    },
    {
      title: 'MSW-1 Sem.',
      url: 'https://drive.google.com/file/d/1USz8UWiH4sxvpbbM3elvHMa2RQskRFVb/view?usp=drivesdk',
    },
    {
      title: 'B.Sc. Phy. Sci.-1st Sem.',
      url: 'https://drive.google.com/file/d/1XIt0lPqXQyD2vq0KCWxGsCINcNWr_p1E/view?usp=drivesdk',
    },
    {
      title: 'BBA-3rd Sem.',
      url: 'https://drive.google.com/file/d/1XbmQUSgSkGENGC0SPjfczXD-WsGRq6su/view?usp=drivesdk',
    },
    {
      title: 'B.Com-Com. App-3rd Sem.',
      url: 'https://drive.google.com/file/d/1_mXCfuwXxSWg82ZT8yHQPPsppOv_GEO1/view?usp=drivesdk',
    },
    {
      title: 'B.SC. Sports-3rd Sem.',
      url: 'https://drive.google.com/file/d/1a-WJtYRAJngobdX00WVCfEWDnGPD2JRF/view?usp=drivesdk',
    },
    {
      title: 'B.Sc. Sports-1 Sem.',
      url: 'https://drive.google.com/file/d/1cahTlgCzgEP3E9ok0obX-kgAm8Ed6aLO/view?usp=drivesdk',
    },
    {
      title: 'M.A. Pub. Admn.-1 Sem.',
      url: 'https://drive.google.com/file/d/1m-imoW934TBCjf7E6gm4Y3o4K0T_kyXq/view?usp=drivesdk',
    },
    {
      title: 'M.Sc. Biotechnology-1 Sem.',
      url: 'https://drive.google.com/file/d/1nf7s55ftNuMk_j6JYURVFkH0nekRUe_E/view?usp=drivesdk',
    },
    {
      title: 'BCA-3rd Sem.',
      url: 'https://drive.google.com/file/d/1ntALRLmJu3LT8JdNJ_F447iKkM_Bor5C/view?usp=drivesdk',
    },
    {
      title: 'B.Com-Comp. App.-1st Sem.',
      url: 'https://drive.google.com/file/d/1pVt3uyyrMjcWJS4b9JUpq0H2NM9FlHfR/view?usp=drivesdk',
    },
    {
      title: 'B.Com-Gen.-1 Sem.',
      url: 'https://drive.google.com/file/d/1pjhuirv93jW3l9SVxRFB28mtVKHRCGDh/view?usp=drivesdk',
    },
    {
      title: 'B.Sc. Phy. Sci.-3rd Sem.',
      url: 'https://drive.google.com/file/d/1uZG5Dv68KY6TMR_6Yfb7chUi7f2T7BHm/view?usp=drivesdk',
    },
  ];
  constructor(
    private showNoticeService: ShowNoticeService,
    private route: ActivatedRoute,
    private noticeService: NoticeService,
    private router: Router,
    private ref:ChangeDetectorRef
  ) {
    showNoticeService.hide();
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      if (this.id === 'result') {
        this.showResultTable = true;
      } else {
        this.showResultTable = false;
        const path = this.noticeService.searchNotice(this.id);
        if (path) {
          if (path.includes('view?usp=sharing')) {
            this.urlPath = path.replace('view?usp=sharing', 'preview');
            // $('#noticeItem').attr('src', this.urlPath);
            // this.urlPath = path;
          } else {
            this.urlPath = path;
            // $('#noticeItem').attr('src', path);
          }
          this.ref.detectChanges();
          $('#noticeItem').attr('src', this.urlPath);
        } else {
          this.router.navigate(['not-found']);
          this.ref.detectChanges();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.showNoticeService.show();
  }
}
