import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lp-twofour-twofive-even',
  templateUrl: './lp-twofour-twofive-even.component.html',
  styleUrls: ['./lp-twofour-twofive-even.component.scss'],
})
export class LpTwofourTwofiveEvenComponent implements OnInit {
  pdfSrc: string[] = [
    'https://drive.google.com/file/d/14ETAAQYvQdQioMmabSpa5yvKNGgVzxly/preview',
    'https://drive.google.com/file/d/11pGlGQYOTrmFFS8RwxUh8IDxd4c7DwSQ/preview',
    'https://drive.google.com/file/d/1m9rdYivdOrYT2XMGYh4VqiKc_wCz2Zg7/preview',
    'https://drive.google.com/file/d/1ovIG_oVJfjaia5m7gfl8ohkh0mQllkBb/preview',
    'https://drive.google.com/file/d/1XGUKRN3crru29Yy99QHtYD8otmnAJeSw/preview',
    'https://drive.google.com/file/d/1TYN_DJk4W0LEthv9B4N7m_TE3kfLhKN5/preview',
    'https://drive.google.com/file/d/1yh3a8g5ZPktbt0qXFHZ6TPnvTdgze9xE/preview',
    'https://drive.google.com/file/d/10x53ghVGWYRVXol6oyglhb-_AifdTjl_/preview',
    'https://drive.google.com/file/d/17pGvU4wjmR4uzVzx0LJMcVz1rSNZXj67/preview',
    'https://drive.google.com/file/d/1UcjuTkvHwggZ_qgRuEGBuAmZ8hPWpG94/preview',
    'https://drive.google.com/file/d/18ZWrhBC1gljb6NJ0M8Kp5JOka20HN__-/preview',
    'https://drive.google.com/file/d/1LK4BHTr2qQ0eDs3e7e_sqPaxcNuPKtMq/preview',
    'https://drive.google.com/file/d/1PH1sIg9FCJLrd_6LfwNPbUMQxp9GDQS8/preview',
    'https://drive.google.com/file/d/12HGglnVnn3TGUCK95w3UchQBt4A8odaP/preview',
    'https://drive.google.com/file/d/1nXkTbl8dDSwsXTfHf09dbalHU9j5BLwo/preview',
    'https://drive.google.com/file/d/1OTUrcOl2pk9_3jVVR8L4vcZWD7LjJrT5/preview',
    'https://drive.google.com/file/d/12C3H-yH-41Bil8GCDrtZ4HlMQHYuuqs2/preview',
    'https://drive.google.com/file/d/1V3B99hhUvsOziRbOcKiGSkds_q1ZzGcr/preview',
    'https://drive.google.com/file/d/1YV2gpLSD9zU-IllOPzxL5UBOnk8tTyAT/preview',
    'https://drive.google.com/file/d/1TVDDd_UAehM7j40XDdVu6sbX4Yg21cdX/preview',
    'https://drive.google.com/file/d/1EDE9U7cU7ePiDrO4AOdNOYcLMJqAn2qZ/preview',
    'https://drive.google.com/file/d/1a2AmYkjykgumSG7gug176MAtfEr5CeGV/preview',
    'https://drive.google.com/file/d/1g7MD1M-N1_g2q7Ls06wuccyx0uMkP5Kn/preview',
  ];

  departments = [
    { id: '1', name: 'DEPARTMENT OF ECONOMICS' },
    { id: '2', name: 'DEPARTMENT OF GEOGRAPHY' },
    { id: '3', name: 'DEPARTMENT OF PUBLIC ADMINISTRATION' },
    { id: '4', name: 'DEPARTMENT OF POLITICAL SCIENCE' },
    { id: '5', name: 'DEPARTMENT OF MATHEMATICS' },
    { id: '6', name: 'DEPARTMENT OF ZOOLOGY' },
    { id: '7', name: 'DEPARTMENT OF SANSKRIT' },
    { id: '8', name: 'DEPARTMENT OF HINDI' },
    { id: '9', name: 'DEPARTMENT OF B.P.Ed.' },
    { id: '10', name: 'DEPARTMENT OF BCA' },
    { id: '11', name: 'DEPARTMENT OF BOTANY' },
    { id: '12', name: 'DEPARTMENT OF COMMERCE' },
    { id: '13', name: 'DEPARTMENT OF PHYSICS' },
    { id: '14', name: 'DEPARTMENT OF SOCIAL WORK' },
    { id: '15', name: 'DEPARTMENT OF MUSIC' },
    { id: '16', name: 'DEPARTMENT OF PUNJABI' },
    { id: '17', name: 'DEPARTMENT OF HISTORY' },
    { id: '18', name: 'DEPARTMENT OF CHEMISTRY' },
    { id: '19', name: 'DEPARTMENT OF INDUSTRIAL CHEMISTRY' },
    { id: '20', name: 'DEPARTMENT OF BIOTECHNOLOGY' },
    { id: '21', name: 'DEPARTMENT OF SPORTS' },
    { id: '22', name: 'DEPARTMENT OF IMB' },
    { id: '23', name: 'DEPARTMENT OF ENGLISH' },
  ];

  src: string;
  isLoading = true;
  activeDepartment: string = '1';
  constructor() {}

  ngOnInit(): void {
    this.changeRoute('1');
  }
  changeRoute(year: string) {
    this.isLoading = true;
    this.activeDepartment = year;
    this.src = this.pdfSrc[parseInt(year) - 1] || ''; // Set the corresponding PDF source
  }
}
