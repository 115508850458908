<iframe id="noticeItem" width="100%" height="480" allow="autoplay" *ngIf="!showResultTable"></iframe>

<div class="container my-5" *ngIf="showResultTable">
    <div class="row">
        <div class="col-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col" class="text-center">View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of result; let i=index">
                        <th scope="row">{{item.title}}</th>
                        <td class="text-center"><a href="{{item.url}}" target="_blank"><i
                                    class="fa-solid fa-eye"></i></a></td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>